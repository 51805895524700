import { useEffect } from 'react';
import NavBar from '../components/NavBar';
import { Helmet } from 'react-helmet';

export default function ButtonFiddle() {
  document.addEventListener('keydown', function (e) {
    console.log('key press' + e.keyCode);
  });
  // detect enter or exit fullscreen mode
  document.addEventListener('webkitfullscreenchange', fullscreenChange);
  document.addEventListener('mozfullscreenchange', fullscreenChange);
  document.addEventListener('fullscreenchange', fullscreenChange);
  document.addEventListener('MSFullscreenChange', fullscreenChange);
  return (
    <NavBar>
      <div className="mt-10">
        <h1 className="font-bold text-4xl mb-1">Button Fiddle</h1>
        <p>Try to click the button as often as possible without touching the circles.<br/>Feel free to post a screenshot of your <span className="text-indigo-800 font-bold">Highscore</span> on Twitter with the Hashtag <span className="text-indigo-800 font-bold">#ButtonFiddle</span> !</p>
      </div>
      <div className="h-5/6 shadow-xl rounded">
        <button onClick={fullscreen} className="relative top-10 bg-white float-right rounded-lg">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
          </svg>
        </button>
        <iframe src="/button-fiddle.html" className="w-full h-screen border-2 rounded-md"/>
      </div>
    </NavBar>
  )
}

function fullscreen() {
  // check if fullscreen mode is available
  if (document.fullscreenEnabled || 
    document.webkitFullscreenEnabled || 
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled) {
    
    // which element will be fullscreen
    var iframe = document.querySelector('iframe');
    // Do fullscreen
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  }
  else {
    document.querySelector('.error').innerHTML = 'Your browser is not supported';
  }
}

function fullscreenChange() {
  if (document.fullscreenEnabled ||
       document.webkitIsFullScreen || 
       document.mozFullScreen ||
       document.msFullscreenElement) {
    console.log('enter fullscreen');
  }
  else {
    console.log('exit fullscreen');
  }
  // force to reload iframe once to prevent the iframe source didn't care about trying to resize the window
  // comment this line and you will see
  var iframe = document.querySelector('iframe');
  iframe.src = iframe.src;
}