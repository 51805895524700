import NavBar from '../components/NavBar';
import LinkModal from '../components/LinkModal';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import '../LandingPage.css';

export default function LandingPage() {
  return (
    <NavBar>
      <Grid container spacing={12}>
        {/* <Grid item xs={12}>
          <div style={{ backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
            backgroundColor: "rgba(223, 219, 229, 0.4)"
          }}
          className="pb-4 px-2 rounded-2xl shadow-xl">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl pt-2" style={{ fontFamily: ['Montserrat', 'sans-serif'] }}>
                  <span className="block xl:inline">Stupid Games</span>{' '}
                  <span className="block text-indigo-600">Omegalul.co</span>
                </h1>
                <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Just some silly games if you're feeling a little bored while watching Papaplatte sucking in all sorts of games :)
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="https://www.papaplatte.shop/"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 focus:ring-4"
                    >
                      OMEGALUL CO. Shop<br/>
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      to="/button-fiddle"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-50 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10 focus:ring-4"
                    >
                      Try Button Fiddle!
                    </Link>
                  </div>
                </div>
              </div>
            </main>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                className="h-auto w-full sm:h-72 md:h-96 lg:w-full lg:h-auto"
                src="Screenshot_3.png"
                alt=""
              />
            </div>
          </div>
        </Grid> */}
        <Grid item xs={12}>
          <div style={{ backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
            backgroundColor: "rgba(223, 219, 229, 0.4)"
          }}
          className="pb-4 px-2 rounded-2xl shadow-xl">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl pt-2" style={{ fontFamily: ['Montserrat', 'sans-serif'] }}>
                  <span className="block xl:inline">Stupid Games</span>{' '}
                  <span className="block text-indigo-600">Omegalul.co</span>
                </h1>
                <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Just some silly games (atm just one) if you're feeling a little bored while watching Papaplatte sucking in all sorts of games :)
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="https://www.papaplatte.shop/"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 focus:ring-4"
                    >
                      OMEGALUL CO. Shop<br/>
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      to="/button-fiddle"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-50 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10 focus:ring-4"
                    >
                      Try Button Fiddle!
                    </Link>
                  </div>
                </div>
              </div>
            </main>
            <div className="lg:relative lg:w-6/12 sm:w-screen" style={{ bottom: 360, left: '55%' }}>
              <img
                className="h-auto sm:h-72 md:h-96 lg:h-auto lg:absolute"
                src="Screenshot_3cut.png"
                alt=""
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
            backgroundColor: "rgba(223, 219, 229, 0.4)"
          }}
          className="pb-4 px-2 rounded-2xl shadow-xl">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl pt-2" style={{ fontFamily: ['Montserrat', 'sans-serif'] }}>
                  <span className="block xl:inline font-bold">Podcast with<br/><span className="block text-white xl:inline">Dominik</span> & <span className="block text-white xl:inline">Kevin</span></span>
                </h1>
                <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Wisdoms of life, Retzmannsche rules and lots of sick stories.<br/>
                  All in all, baba content!
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="https://open.spotify.com/show/1cNRg79HvjV8XC7LvGwqV0"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-black hover:bg-gray-900 md:py-4 md:text-lg md:px-10 focus:ring-4"
                    >
                      Spotify
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a
                      href="apple.co/2zFsZlj"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-black bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10 focus:ring-4"
                    >
                      Apple Podcasts
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a
                      href="https://www.deezer.com/de/show/70781"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-black hover:bg-gray-900 md:py-4 md:text-lg md:px-10 focus:ring-4"
                    >
                      Deezer
                    </a>
                  </div>
                </div>
              </div>
            </main>
            <div className="lg:relative lg:w-6/12 sm:w-screen" style={{ bottom: 340, left: '55%' }}>
              <img
                className="h-auto sm:h-72 md:h-96 lg:h-auto lg:absolute shadow-2xl"
                src="edeltalk.jpg"
                alt=""
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
            backgroundColor: "rgba(223, 219, 229, 0.4)"
          }}
          className="pb-4 px-2 rounded-2xl shadow-xl">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl pt-2" style={{ fontFamily: ['Montserrat', 'sans-serif'] }}>
                  <span className="block xl:inline font-bold">Papalatte on <br/><span className="block text-indigo-600 xl:inline">Socialmedia</span></span>
                </h1>
                <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  All kinds of platforms where you can marvel at the full-time mail opener with a tendency to sickly laughing friends.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <LinkModal title="Spotify" bgcolor="bg-green-500" hovercolor="bg-green-600" links={[{ title: 'Music/Rap (Papaplatte)', href: 'https://open.spotify.com/artist/587WC9AiWHdXXBSOakO3Di' }, { title: 'Podcast (Edeltalk)', href: 'https://open.spotify.com/show/1cNRg79HvjV8XC7LvGwqV0' }]}/>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a
                      href="https://www.twitch.tv/papaplatte"
                      target="_blank"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-50 bg-purple-800 hover:bg-purple-900 md:py-4 md:text-lg md:px-5 focus:ring-4"
                    >
                      Twitch
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <LinkModal title="YouTube" bgcolor="bg-red-600" hovercolor="bg-red-700" links={[{ title: 'Main (Papaplatte)', href: 'https://www.youtube.com/Papaplatte' }, { title: 'Gaming (Papaplatte Gaming)', href: 'https://www.youtube.com/PapaplatteGaming' }, { title: 'Reaction (Domo)', href: 'https://www.youtube.com/domopapaplatte' }]}/>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <LinkModal title="Twitter" bgcolor="bg-blue-500" hovercolor="bg-blue-600" links={[{ title: 'Main (Papaplatte)', href: 'https://twitter.com/papaplatte' }, { title: 'Podcast (Edeltalk)', href: 'https://twitter.com/edeltalk' }]}/>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <LinkModal title="Instagram" bgcolor="bg-yellow-600" hovercolor="bg-yellow-700" links={[{ title: 'Main (Papaplatte)', href: 'https://www.instagram.com/papaplatte/' }, { title: 'Podcast (Edeltalk)', href: 'https://www.instagram.com/edeltalk/' }]}/>
                  </div>
                </div>
              </div>
            </main>
            <div className="lg:relative lg:w-6/12 sm:w-screen" style={{ bottom: 360, left: '55%' }}>
              <img
                className="h-auto sm:h-72 md:h-96 lg:h-auto lg:absolute"
                src="Screenshot_1.png"
                alt=""
              />
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={12}>
          <div 
          className="flex-row">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item style={{ backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
                backgroundColor: "rgba(223, 219, 229, 0.4)"
              }}
              className="rounded-2xl shadow-xl w-screen p-8">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="sm:text-center lg:text-left">
                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl pt-2" style={{ fontFamily: ['Montserrat', 'sans-serif'] }}>
                      <span className="block xl:inline font-bold">Podcast with<br/><span className="block text-indigo-600 xl:inline">Dominik</span> & <span className="block text-indigo-600 xl:inline">Kevin</span></span>
                    </h1>
                    <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      Wisdoms of life, Retzmannsche rules and lots of sick stories.<br/>
                      All in all, baba content!
                    </p>
                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                      <div className="rounded-md shadow">
                        <a
                          href="https://open.spotify.com/show/1cNRg79HvjV8XC7LvGwqV0"
                          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 focus:ring-4"
                        >
                          Spotify
                        </a>
                      </div>
                      <div className="mt-3 sm:mt-0 sm:ml-3">
                        <a
                          href="https://www.deezer.com/de/show/70781"
                          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-50 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10 focus:ring-4"
                        >
                          Deezer OMEGALUL!
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item className="absolute right-0 w-6/12">
                <div>
                  <img
                    src="edeltalk.jpg"
                    alt=""
                    className="shadow-2xl"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div 
          className="px-2 flex-row">
            <Grid container direction="row" justifyContent="space-around" alignItems="center">
              <Grid item style={{ backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
                backgroundColor: "rgba(223, 219, 229, 0.4)"
              }}
              className="rounded-2xl shadow-xl w-screen p-6">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="sm:text-center lg:text-left">
                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl pt-2" style={{ fontFamily: ['Montserrat', 'sans-serif'] }}>
                      <span className="block xl:inline font-bold">Podcast with<br/><span className="block text-indigo-600 xl:inline">Dominik</span> & <span className="block text-indigo-600 xl:inline">Kevin</span></span>
                    </h1>
                    <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      Wisdoms of life, Retzmannsche rules and lots of sick stories.<br/>
                      All in all, baba content!
                    </p>
                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                      <div className="rounded-md shadow">
                        <a
                          href="https://open.spotify.com/show/1cNRg79HvjV8XC7LvGwqV0"
                          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 focus:ring-4"
                        >
                          Spotify
                        </a>
                      </div>
                      <div className="mt-3 sm:mt-0 sm:ml-3">
                        <a
                          href="https://www.deezer.com/de/show/70781"
                          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-50 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10 focus:ring-4"
                        >
                          Deezer OMEGALUL!
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item className="absolute right-0 w-6/12">
                <div>
                  <img
                    src="Screenshot_3cut.png"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid> */}
      </Grid>
    </NavBar>
  )
}