import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import Grid from '@mui/material/Grid';

export default function LinkModal(props) {
  const { links, title, bgcolor, hovercolor } = props;
  let [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <p
        onClick={() => {setModalOpen(true)}}
        className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-50 ${bgcolor ? bgcolor : 'bg-red-600'} hover:${hovercolor ? hovercolor : 'hover:bg-red-700'} md:py-4 md:text-lg md:px-5 focus:ring-4 select-none cursor-pointer focus:ring-4`}
      >
        {title}
      </p>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setModalOpen(false)}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-5 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-dashed border-2 border-gray-800"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-bold leading-6 text-indigo-600"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Grid item>
                      {title} Accounts
                    </Grid>
                    <Grid item>
                      <button
                        type="button"
                        className="float-right inline-flex justify-center px-2 py-1 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={() => setModalOpen(false)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </Grid>
                  </Grid>
                </Dialog.Title>
                <div className="w-full mt-2 border-b-2"/>
                <div className="mt-2 mb-2">
                  <p className="text-sm text-gray-500">
                    All the different {title} Accounts, Papaplatte is involved in.
                  </p>
                </div>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  {links.map((link, index) => (
                    <Grid item>
                      <div className="mt-3 sm:mt-0 sm:ml-3">
                        <a
                          href={link.href}
                          target="_blank"
                          className="inline-flex justify-center px-4 py-2 text-base font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 select-none cursor-pointer"
                        >
                          {link.title}
                        </a>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
