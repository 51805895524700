import "./App.css";
import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ButtonFiddle from "./pages/ButtonFiddle";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/button-fiddle" element={<ButtonFiddle />} />
            </Routes>
        </Router>
    );
}

export default App;
